import { Button } from 'components/atoms/Button/Button';
import { Input } from 'components/atoms/Input/Input';
import { Select } from 'components/atoms/Select/Select';
import { SelectAndComplement } from 'components/molecules/Select-and-complement/Select-and-complement';
import { useFormik } from 'formik';
import { IDetailStep } from 'models/info.steps.interface';
import { IAppReducer } from 'models/reducer.interface';
import {
  countryIndicatorOptions,
  countryOptions,
} from 'pages/Create-account/pages/Personal-information/constants/Personal-information.constants';
import { updateUser } from 'shared/services/users';
import { updateUserAction } from 'pages/sing-in/store/actions/auth.types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { getUserByUid } from 'shared/services/user';
import { getPhoneIndicator, lengthPhone } from 'utils/branch.utils';
import './Edit-profile.scss';
import {
  IFormEditProfileInformation,
  IFormErrosEditProfileInformation,
} from './models/Edit-profile.interface';
import { HeaderWithAlert } from 'components/organisms/Header-with-alert/Header-with-alert';
import { TitleWhitImage } from 'components/atoms/Title-whit-image/Title-whit-image';
import { Images } from 'assets/images/images.index';
import { Divider } from 'components/atoms/Divider/Divider';
import { getContactInfo } from 'utils/connect-info.utils';
import { ItemList } from 'components/atoms/Item-list/Item-list';

export const EditProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    authReducer,
    branchReducer: { country_id, branch },
  } = useSelector((state: IAppReducer) => state);
  const steps: IDetailStep[] = [
    {
      description: 'Ajsutes',
      state: 'active',
      link: '/settings',
      permissionToNavigate: true,
    },
    {
      description: 'Perfil',
      state: 'active',
      link: '/edit-profile',
      permissionToNavigate: true,
    },
  ];
  const formProfileIformationInitialValues = {
    photURL: authReducer.photoURL || '',
    name: authReducer.name || '',
    lastname: authReducer.lastname || '',
    email: authReducer.email || '',
    indicator: authReducer.indicator || getPhoneIndicator(branch),
    phone: authReducer.phone || '',
    country_id: '',
    documentNumber: '',
  };
  const formProfileIformationValidate = (
    values: IFormEditProfileInformation
  ) => {
    const errors: IFormErrosEditProfileInformation = {};
    const phoneLength = lengthPhone(branch);
    if (!values.name) {
      errors.name = 'Este campo es requerido';
    }
    if (!values.lastname) {
      errors.lastname = 'Este campo es requerido';
    }
    if (!values.indicator) {
      errors.indicator = 'Este campo es requerido';
    }
    if (!values.phone) {
      errors.phone = 'Este campo es requerido';
    } else if (values.phone.length < phoneLength.min) {
      errors.phone = 'Teléfono muy corto';
    } else if (values.phone.length > phoneLength.max) {
      errors.phone = 'Teléfono muy largo';
    }
    if (branch !== 'pr') {
      if (!values.documentNumber) {
        errors.documentNumber = 'Este campo es requerido';
      }
    }

    return errors;
  };
  const formProfileIformation = useFormik({
    initialValues: formProfileIformationInitialValues,
    validate: formProfileIformationValidate,
    onSubmit: async (values) => {
      const userCms = await getUserByUid(authReducer.uid as string);
      const uid = userCms.attributes.auth_id;
      const dataRequest: {
        uidAgent?: string;
        email?: string;
        name: string;
        lastname: string;
        indicator: string;
        phone: string;
      } = {
        name: values.name,
        lastname: values.lastname,
        indicator: values.indicator,
        phone: values.phone,
        uidAgent: undefined,
        email: undefined,
      };
      if (authReducer.agentId && values.email !== authReducer.email) {
        dataRequest.uidAgent = authReducer.agentAuthData?.uid;
        dataRequest.email = values.email;
      }
      await updateUser(dataRequest, uid);
      dispatch(updateUserAction(dataRequest));
      navigate('/settings/profile');
    },
  });
  return (
    <>
      <HeaderWithAlert
        title={''}
        message={``}
        steps={steps}
        showAlert={undefined}
        onClick={() => {
          navigate('/settings');
        }}
      ></HeaderWithAlert>
      <div className="edit__profile__container">
        <TitleWhitImage
          title="Actualiza tu  <br />información"
          image={Images.srConnectBlack}
          styleInLineImage={{ width: '2.62781rem', height: '6.25rem' }}
        ></TitleWhitImage>
        <form
          className="edit__profile__form"
          onSubmit={formProfileIformation.handleSubmit}
          noValidate
        >
          <Input
            WrapperClassName="grid__span-6"
            label="Correo Electrónico"
            id="edit-profile-email"
            type="email"
            name="email"
            onChange={
              authReducer.agentId
                ? formProfileIformation.handleChange
                : () => {
                    return;
                  }
            }
            onBlur={
              authReducer.agentId
                ? formProfileIformation.handleBlur
                : () => {
                    return;
                  }
            }
            value={formProfileIformation.values.email || ''}
            isTouched={formProfileIformation.touched.email}
            errorInput={formProfileIformation.errors.email}
            errorLabel={formProfileIformation.errors.email}
            isRequired={false}
            isDisabled={authReducer.agentId ? false : true}
            form={formProfileIformation}
          />
          <Input
            WrapperClassName="grid__span-6"
            label="Nombre"
            id="edit__profile-name"
            type="text"
            name="name"
            onChange={formProfileIformation.handleChange}
            onBlur={formProfileIformation.handleBlur}
            value={formProfileIformation.values.name}
            isTouched={formProfileIformation.touched.name}
            errorInput={formProfileIformation.errors.name}
            errorLabel={formProfileIformation.errors.name}
            isRequired={true}
            form={formProfileIformation}
          />
          <Input
            WrapperClassName="grid__span-6"
            label="Apellido"
            id="edit__profile-lastname"
            type="text"
            name="lastname"
            onChange={formProfileIformation.handleChange}
            onBlur={formProfileIformation.handleBlur}
            value={formProfileIformation.values.lastname}
            isTouched={formProfileIformation.touched.lastname}
            errorInput={formProfileIformation.errors.lastname}
            errorLabel={formProfileIformation.errors.lastname}
            isRequired={true}
            form={formProfileIformation}
          />
          <Divider
            classWrapper="edit__profile__form__divider"
            margin="notMargin"
          ></Divider>
          <Select
            WrapperClassName="grid__span-6"
            id="edit__profile-country"
            placeholder="País"
            value={country_id.toString()}
            name="country"
            onClick={() => {
              return;
            }}
            options={[...countryOptions]}
            isDisabled={true}
            errorSelect={''}
            onBlur={() => {
              return;
            }}
            isTouched={false}
          />
          <Divider
            classWrapper="edit__profile__form__divider"
            margin="notMargin"
          ></Divider>
          {branch !== 'pr' && (
            <Input
              label="Cédula Personal"
              id="personal-information-documentNumber"
              type="tel"
              name="documentNumber"
              onChange={formProfileIformation.handleChange}
              onBlur={formProfileIformation.handleBlur}
              value={formProfileIformation.values.documentNumber}
              isTouched={formProfileIformation.touched.documentNumber}
              errorInput={formProfileIformation.errors.documentNumber}
              errorLabel={formProfileIformation.errors.documentNumber}
              isRequired={true}
              form={formProfileIformation}
            />
          )}
          <SelectAndComplement
            WrapperClassName=" grid__span-6"
            label="Teléfono"
            nameSelect="indicator"
            idSelect="edit__profile-indicator"
            onChange={
              authReducer.agentId
                ? formProfileIformation.handleChange
                : () => {
                    return;
                  }
            }
            onChangeSelect={() => {
              return;
            }}
            valueSelect={formProfileIformation.values.indicator}
            errorSelect={formProfileIformation.errors.indicator}
            options={[...countryIndicatorOptions]}
            idInput="edit__profile-number"
            type="tel"
            nameInput="phone"
            onBlurInput={
              authReducer.agentId
                ? formProfileIformation.handleBlur
                : () => {
                    return;
                  }
            }
            onBlurSelect={() => {
              return;
            }}
            valueInput={formProfileIformation.values.phone}
            inputIsTouched={formProfileIformation.touched.phone}
            errorInput={formProfileIformation.errors.phone}
            form={formProfileIformation}
            selectIsDisabled={authReducer.agentId ? false : true}
            inputIsDisabled={authReducer.agentId ? false : true}
          ></SelectAndComplement>
          <Button
            className="edit__profile__form__button"
            color="primary"
            size="medium"
            template="primary"
            typeStyle="rounded"
            type="button"
            label={'guardar cambios'}
            isDisabled={
              formProfileIformation.isSubmitting ||
              !formProfileIformation.isValid ||
              !formProfileIformation.dirty
            }
            isLoading={formProfileIformation.isSubmitting}
            onClick={formProfileIformation.handleSubmit}
          ></Button>
          <ItemList>
            Ver documento de{' '}
            <a
              href={`${getContactInfo(branch).url_terms}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              términos <br />y condiciones
            </a>
          </ItemList>
        </form>
      </div>
    </>
  );
};
