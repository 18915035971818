export const enum TypeActionsSignIn {
  login = '[SIGN IN LOGIN] set user',
  authenticatedUser = '[SIGN IN LOGIN] set authenticated user',
  logout = '[SIGN IN LOGOUT] remove user',
  resetPassword = '[RESET PASSWORD] set reset password',
  updateUser = '[SIGN IN LOGIN] update user',
}
interface IUpdateUserActionPayload {
  name?: string;
  lastname?: string;
  email?: string;
  phone?: string;
  plansId?: string;
}
interface IauthActionPayload {
  agentId: number;
  accessToken: string;
  uid: string;
  displayName?: string;
  name?: string;
  lastname?: string;
  email: string;
  phone?: string;
  phoneNumber?: string;
  photoURL: string;
  idToken?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  userAuth: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  agentAuthData?: any;
  companyId: {
    id: number;
  };
  plansId: string | undefined;
  formToShow: string | undefined;
  user_type: string | undefined;
}
export type authAction = {
  type: TypeActionsSignIn;
  data: IauthActionPayload;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setUser = (user: any) => ({
  type: TypeActionsSignIn.login,
  data: user,
});
export const updateUserAction = (user: IUpdateUserActionPayload) => ({
  type: TypeActionsSignIn.updateUser,
  data: user,
});

export const setAuthenticatedUser = (value: boolean) => ({
  type: TypeActionsSignIn.authenticatedUser,
  data: value,
});

export const setSignOut = () => ({
  type: TypeActionsSignIn.logout,
});

export const setResetPassword = (value: boolean) => ({
  type: TypeActionsSignIn.resetPassword,
  data: value,
});
