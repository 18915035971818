import { useNavigate } from 'react-router';
import './Renewal-subscription-page.scss';
import { HeaderWithSteps } from 'components/organisms/Header-with-steps/Header-with-steps';
import { useFormik } from 'formik';
import {
  IFormRenewalSubscription,
  IFormRenewalSubscriptionErrors,
} from './models/renewal-subscription.interface';
import { stepsRenewalSubscription } from './models/renewal-subscription.constants';
import { DetailAccordion } from 'components/atoms/Detail-accordion/Detail-accordion';
import { useState } from 'react';
import { TitleWhitImage } from 'components/atoms/Title-whit-image/Title-whit-image';
import { Images } from 'assets/images/images.index';
import { Input } from 'components/atoms/Input/Input';
import { Button } from 'components/atoms/Button/Button';
import { renewalSubscriptionService } from 'shared/services/payments';

export const RenewalSubscriptionPage = () => {
  const navigate = useNavigate();
  const [errorPayment, setErrorPayment] = useState(false);
  const goToHome = () => {
    navigate('/settings/payments');
  };

  const formRenewalSubcriptionInitialValues: IFormRenewalSubscription = {
    idSubscription: '',
  };
  const formRenewalSubcriptionValidate = (values: IFormRenewalSubscription) => {
    const errors: IFormRenewalSubscriptionErrors = {};
    if (!values.idSubscription) {
      errors.idSubscription = 'Este campo es requerido';
    }
    return errors;
  };

  const renewalSubscription = async(values: IFormRenewalSubscription) => {
    await renewalSubscriptionService(values.idSubscription);
    goToHome();
  };

  const formRenewalSubscription = useFormik({
    initialValues: formRenewalSubcriptionInitialValues,
    validate: formRenewalSubcriptionValidate,
    onSubmit: async (values) => {
      try {
        setErrorPayment(false);
        renewalSubscription(values);
      } catch (error) {
        setErrorPayment(true);
      } finally {
        formRenewalSubscription.setSubmitting(false);
      }
    },
  });
  return (
    <>
      <HeaderWithSteps
        navbarTemplate="light"
        steps={stepsRenewalSubscription}
        onClick={goToHome}
        notProgress={true}
      />
      <div className="renewal__subscription__container">
        {errorPayment && (
          <>
            <DetailAccordion tilte="ERROR EN EL PAGO" type="error">
              Ocurrió un error al renovar la suscripción con id{' '}
              {formRenewalSubscription.values.idSubscription}
            </DetailAccordion>
          </>
        )}
        <TitleWhitImage
          title="Información <br /> de la suscripción"
          image={Images.paymentInfo}
          styleInLineImage={{ width: '2.62781rem', height: '6.25rem' }}
        ></TitleWhitImage>
        <div className="renewal__subscription__content">
          <Input
            label="Id de suscripción"
            id="id-subscription"
            type="charcode"
            name="idSubscription"
            onChange={formRenewalSubscription.handleChange}
            onBlur={formRenewalSubscription.handleBlur}
            value={formRenewalSubscription.values.idSubscription}
            isTouched={formRenewalSubscription.touched.idSubscription}
            errorInput={formRenewalSubscription.errors.idSubscription}
            errorLabel={formRenewalSubscription.errors.idSubscription}
            isRequired={true}
            form={formRenewalSubscription}
          />
          <Button
            color="primary"
            size="medium"
            template="primary"
            typeStyle="rounded"
            className="from__login__button"
            type="submit"
            onClick={formRenewalSubscription.handleSubmit}
            isDisabled={
              formRenewalSubscription.isSubmitting ||
              !formRenewalSubscription.isValid
            }
            isLoading={formRenewalSubscription.isSubmitting}
          >
            Continuar
          </Button>
        </div>
      </div>
    </>
  );
};
