import {
  GenericSectionError,
  ItemCard,
  ItemPayment,
  PaymentMethodItemEmpty,
  PlanItemEmpty,
  ServicesHistory,
  ServicesHistorySkeleton,
} from 'components';
import { IPropsServicesHistory } from 'components/organisms/Services-history/Services-history';
import { IAppReducer } from 'models';
import { ICard, ICreditCar } from 'models/card.interface';
import { Images } from 'assets/images/images.index';
import { IUserSubscription } from 'pages/Edit-Property/models/Edit-Property.interface';
import { PeriodicityOptions } from 'models/periodicity.interface';
import { TypeProperties } from 'pages/Settings/models/Properties.interface';

const getStatus = (status: number) => {
  return {
    1: 'successful',
    2: 'cancelled',
    3: 'error',
    4: 'pending',
    5: 'timedOut',
  }[status] as 'error' | 'successful' | 'pending' | 'cancelled' | 'timedOut';
};

export const usePaymentsBillingRender = () => {
  const renderPayments = (
    payments: IPropsServicesHistory['data'],
    branch: string,
    user: IAppReducer['authReducer']
  ) => {
    if (payments) {
      return (
        <div className="payments__content not--padding">
          <ServicesHistory
            branch={branch}
            data={payments}
            agentId={user.agentId ?? ''}
            userType={
              user.user_type && !user.agentId ? user.user_type : undefined
            }
          />
        </div>
      );
    } else if (payments === undefined) {
      return (
        <div className="payments__content">
          <ServicesHistorySkeleton />
        </div>
      );
    } else {
      return (
        <div className="payments__content">
          <GenericSectionError template="tertiary" />
        </div>
      );
    }
  };
  const renderUserCards = (
    userCards: null | undefined | ICreditCar[],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    infoPopUp: any,
    showPopUp: boolean,
    handlePopUp: (id?: number | string) => Promise<void>,
    showPopUpEditCardCredit: (card: ICard) => void
  ) => {
    if (userCards === null) {
      return <GenericSectionError template="secondary" />;
    } else if (userCards && userCards.length > 0) {
      return (
        <div className="payments__content">
          <div className="payments__container__items">
            {userCards.map((card, index: number) => (
              <ItemPayment
                WrapperClassName={`${
                  infoPopUp?.id === card.id && !showPopUp ? 'bounceOut' : ''
                }`}
                key={index}
                status="successful"
                is_principal={card.is_principal}
                brand={card.brand}
                country={card.country}
                customer={card.customer}
                exp_year={card.exp_year}
                last4={card.last4}
                exp_month={card.exp_month}
                id={card.id}
                customer_id={card.customer_id}
                onClickDelete={handlePopUp}
                onClickEdit={
                  userCards.length > 1
                    ? () => showPopUpEditCardCredit(card)
                    : undefined
                }
              />
            ))}
          </div>
        </div>
      );
    } else {
      return <PaymentMethodItemEmpty />;
    }
  };
  const renderUserPlans = (
    userPlans: IUserSubscription[],
    user: IAppReducer['authReducer'],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    infoPopUp: any,
    showPopUp: boolean,
    goToWhatsApp: () => void,
    handlePopUPDetailPayment: (id: number | string) => void,
    handlePopUp: (id: number | string) => void,
    addAddOns: (id?: number | string) => Promise<void>,
    handleRenewalSubscription: (subscriptionId: string) => Promise<void>,
    setInfoSubscription: (
      subscriptionId: string,
      planId: number | string
    ) => void,
    goToPlanDetail: (id: number | string) => void
  ) => {
    const renderError = () => <GenericSectionError template="primary" />;

    const renderSkeleton = () => (
      <PlanItemEmpty
        userType={user.user_type && !user.agentId ? user.user_type : undefined}
      />
    );

    const isPlanEditable = (plan: IUserSubscription) => {
      const status = getStatus(plan.attributes.estados_de_suscripcion.data.id);
      return (
        !['pending', 'error', 'cancelled','timedOut'].includes(status) &&
        !plan.attributes.plan_id.data.attributes.isFreemium
      );
    };

    const shouldShowSubscriptionInfo = (
      plan: IUserSubscription,
      user: IAppReducer['authReducer']
    ) => {
      if (!isPlanEditable(plan)) {
        return undefined;
      }

      if (user.agentId !== undefined) {
        if (!plan.attributes.plan_id.data.attributes.is_addon) {
          return () =>
            setInfoSubscription(
              plan.attributes.stripe_subscription_id,
              plan.id
            );
        }

        if (
          user.email === 'lmape@connect.com.co' ||
          user.email === 'scintron@connect.pr'
        ) {
          return () =>
            setInfoSubscription(
              plan.attributes.stripe_subscription_id,
              plan.id
            );
        }

        return undefined;
      }

      return goToWhatsApp;
    };

    const isAnnualAutoPlan = (plan: IUserSubscription) => {
      const productAttributes =
        plan.attributes.plan_id.data.attributes.productos.data[0].attributes;
      return (
        productAttributes.periocity_id.data.attributes.name ===
          PeriodicityOptions.ANUAL &&
        productAttributes.alias_product_type === TypeProperties.AUTO &&
        !['pending', 'error', 'cancelled'].includes(
          getStatus(plan.attributes.estados_de_suscripcion.data.id)
        ) &&
        !plan.attributes.plan_id.data.attributes.is_addon
      );
    };

    const getAddOnsAction = (
      plan: IUserSubscription,
      user: IAppReducer['authReducer']
    ) => {
      if (isAnnualAutoPlan(plan)) {
        return user.agentId !== undefined ? addAddOns : goToWhatsApp;
      }
      return undefined;
    };
    const getTimeRangePlanCancelled = (date: string) => {
      if (!date) return false;
      const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
      const fiveDaysInMilliseconds = 5 * oneDayInMilliseconds;
      const currentDate = new Date();
      const cancelDate = new Date(date);
      const timeDifference = currentDate.getTime() - cancelDate.getTime();
      return timeDifference <= fiveDaysInMilliseconds && timeDifference >= 0;
    };

    const canRenewSubscription = (
      plan: IUserSubscription,
      user: IAppReducer['authReducer']
    ) => {
      const status = getStatus(plan.attributes.estados_de_suscripcion.data.id);
      return user.agentId !== undefined &&
        !['pending', 'error', 'successful'].includes(status) &&
        !plan.attributes.plan_id.data.attributes.isFreemium &&
        getTimeRangePlanCancelled(plan.attributes.date_cancel)
        ? () =>
            handleRenewalSubscription(plan.attributes.stripe_subscription_id)
        : undefined;
    };

    const getChipInfo = (plan: IUserSubscription) => {
      let text = `${plan.attributes.plan_id.data.attributes.productos.data[0].attributes?.periocity_id.data.attributes.name}`;
      if (plan.attributes.plan_id.data.attributes.isFreemium) {
        text = '10 % de descuento en servicios';
      } else {
        text += ` ${
          plan.attributes.plan_id.data.attributes?.user_products
            ? plan.attributes.plan_id.data.attributes.user_products[0].quantity
            : 0
        }/${
          plan.attributes.plan_id.data.attributes.productos.data[0].attributes
            .max_count
        }`;
      }
      if (!plan.attributes.plan_id.data.attributes.is_addon) {
        return [text];
      }

      const profit: string[] = [];
      plan.attributes.plan_id.data.attributes.productos.data.forEach(
        (product) => {
          profit.push(product.attributes.profit_title);
        }
      );
      return [text, ...profit];
    };
    const renderPlans = () => (
      <div className="payments__content">
        <div className="payments__container__items">
          {userPlans.map((plan, index) => (
            <div key={index} style={{ position: 'relative' }}>
              <div className="icon_pasarela">
                {user.agentId !== undefined &&
                  !plan.attributes.plan_id.data.attributes.isFreemium &&
                  (!plan.attributes?.provider_processor ||
                  plan.attributes?.provider_processor.toLowerCase() ===
                    'stripe' ? (
                    <a
                      href={
                        plan.attributes.plan_id.data.attributes.payment_provider
                          .id === 2
                          ? `https://dashboard.stripe.com/customers/${plan.attributes.customer_stripe_id}`
                          : `https://dashboard.stripe.com/subscriptions/${plan.attributes.stripe_subscription_id}`
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={Images.stripe} alt="logo pasarela" />
                    </a>
                  ) : (
                    <a
                      href="https://dashboard.dlocal.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={Images.dlocal} alt="logo pasarela" />
                    </a>
                  ))}
              </div>
              <ItemCard
                labelDeleteOption="Cancelar"
                testid={`test_${plan.id}`}
                WrapperClassName={`${
                  infoPopUp?.id === plan.id && !showPopUp ? 'bounceOut' : ''
                }`}
                id={plan.id}
                key={`card-${index}`}
                type={
                  plan.attributes.plan_id.data.attributes.alias_product_type
                }
                title={plan.attributes.plan_id.data.attributes.alias_name}
                label="Plan"
                chipsInfo={getChipInfo(plan)}
                isReadOnly={
                  plan.attributes.plan_id.data.attributes.read_only ||
                  plan.attributes.plan_id.data.attributes.isFreemium
                }
                status={getStatus(
                  plan.attributes.estados_de_suscripcion.data.id
                )}
                onClickEdit={shouldShowSubscriptionInfo(plan, user)}
                onClickCustomAction={
                  user.agentId !== undefined &&
                  !plan.attributes.plan_id.data.attributes.isFreemium
                    ? (id) => handlePopUPDetailPayment(id)
                    : undefined
                }
                onClickDetail={() => {
                  goToPlanDetail(plan.id);
                }}
                onClickDelete={
                  !['pending', 'error', 'cancelled'].includes(
                    getStatus(plan.attributes.estados_de_suscripcion.data.id)
                  ) && !plan.attributes.plan_id.data.attributes.is_addon
                    ? user.agentId !== undefined
                      ? handlePopUp
                      : goToWhatsApp
                    : undefined
                }
                onClickAddAddOns={getAddOnsAction(plan, user)}
                onClickRenewal={canRenewSubscription(plan, user)}
              />
            </div>
          ))}
        </div>
      </div>
    );

    if (userPlans === null) {
      return renderError();
    } else if (userPlans.length > 0) {
      return renderPlans();
    } else {
      return renderSkeleton();
    }
  };
  return { renderPayments, renderUserCards, renderUserPlans };
};
