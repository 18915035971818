import { Icons } from 'assets/icons/icons.index';
import { ButtonClose } from 'components/atoms/Button-close/Button-close';
import { PopupOverlay } from 'components/atoms/Popup-overlay/Popup-overlay';
import { useState } from 'react';
import './Popup-add-on.scss';
import { Button } from 'components/atoms/Button/Button';
import { Loader, TagLabel } from 'components/atoms';
import { IUserSubscription } from 'pages/Edit-Property/models/Edit-Property.interface';
import { InfoChangePlanAddOns } from 'pages/Settings/pages/Payments-billing/models/Payments.interface';
import { formatPrice } from 'utils/payments.utils';

interface IPropsPopupAddOn {
  addOn: string[];
  closePopUp: () => void;
  planAddOn?: IUserSubscription[];
  process: () => Promise<void>;
  infoAddOns: InfoChangePlanAddOns;
  branch: string;
}
export const PopupAddOn = ({
  addOn,
  closePopUp,
  infoAddOns,
  planAddOn,
  process,
  branch,
}: IPropsPopupAddOn) => {
  const [isLoading, setIsLoading] = useState(false);

  const addAddOns = async () => {
    try {
      setIsLoading(true);
      if (isLoading) return;
      await process();
    } catch {
      console.log('Error poner alerta roja');
    } finally {
      setIsLoading(false);
      closePopUp();
    }
  };
  return (
    <>
      <PopupOverlay>
        <div className={`popup__addon__container`}>
          <ButtonClose
            size="medium"
            className="popup__addon__close"
            onClick={closePopUp}
            template="dark"
          />
          <img
            className="popup__addon__icons"
            src={Icons.contactCenter}
            alt="Soporte"
          />
          <div className="popup__addon__body">
            <div className="popup__addon__body__title">
              <h1>¡adicionales!</h1>
              <p>El beneficio adicional disponible es: </p>
            </div>
            <div className="popup__addon__body__content">
              {planAddOn?.length ? (
                <>
                  <div className="popup__addon__body__content__tags">
                    {addOn.map((benefic, index) => (
                      <TagLabel key={index} typeStyle="rounded" type="primary">
                        {benefic}
                      </TagLabel>
                    ))}
                  </div>
                  {infoAddOns && (
                    <div className="popup__addon__body__content__info">
                      <span className="orange bold">{` Tu plan anual pasará de $${formatPrice(
                        infoAddOns.price,
                        branch
                      )} a $${formatPrice(
                        infoAddOns.newPrice,
                        branch
                      )}.`}</span>
                      <span>
                        {' '}
                        {` Para que el cambio sea efectivo debe pagar`}
                      </span>
                      <span className="bold">{` pagar una diferencia de $${formatPrice(
                        infoAddOns.deference,
                        branch
                      )}.`}</span>
                    </div>
                  )}
                </>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className="popup__addon__actions">
            <Button
              color="primary"
              size="medium"
              template="secondary"
              typeStyle="square"
              className="popup__addon__button"
              type="button"
              isDisabled={false}
              onClick={closePopUp}
            >
              salir
            </Button>
            <Button
              color="primary"
              size="medium"
              template="primary"
              typeStyle="square"
              className="popup__addon__button"
              type="button"
              isDisabled={!planAddOn?.length}
              onClick={addAddOns}
            >
              Realizar pago
            </Button>
          </div>
        </div>
      </PopupOverlay>
      {isLoading && <Loader />}
    </>
  );
};
