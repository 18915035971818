import {
  Button,
  Checkbox,
  Input,
  SelectAndComplement,
  Divider,
  HaveAccount,
  TitleWhitImage,
  PopupDetailAccordion,
  CartCheckout,
  Select
} from 'components';
import * as personalInformationConstants from './constants/Contact-information.constants';
import { Images } from 'assets/images/images.index';
import { Icons } from 'assets/icons/icons.index';
import { IProduct } from '../Payment-information/models/Payment-information.interface';
import { TypeProperties } from 'pages/Settings/models/Properties.interface';
import { ErrorSvg } from 'assets/icons/error';
import { useContactInformation } from './hooks/useContactInformation';
import './Contact-information.scss';

export const ContactInformation = (props: {
  handleStep: (index: number) => void;
}) => {
  const {
    branch,
    checkoutReducer,
    formPersonalInformation,
    cartCheckoutRef,
    handleFocus,
    handleComplemetInfo,
    updateInfoLead,
    handleOptionCountry,
    getZipCodeInfo,
    handleOptionDistrict,
    handleOptionCanton,
    handleOptionProvince,
    handleIndicatorCountry,
    countryAdministrativeDivision,
    handleChangeAcceptTermsYearCar,
    handleChangeCheckTerms,
    navigate,
    year,
  } = useContactInformation({ ...props });

  return (
    <form
      className="checkout__personal__information__container container__generic"
      onSubmit={formPersonalInformation.handleSubmit}
      noValidate
    >
      <PopupDetailAccordion title="Resumen orden" icon={Icons.factura}>
        <div style={{ height: '24px' }}></div>
        <CartCheckout
          branch={branch}
          planItemsQuantity={
            checkoutReducer.order?.dataProducts?.planItemsQuantity
          }
          allProducts={checkoutReducer.products as IProduct[]}
          orderId={checkoutReducer.order?.id as string}
          coupon={checkoutReducer.order?.dataOrderCompleted?.coupon}
          ref={cartCheckoutRef}
          templete="default"
          agentId={
            (checkoutReducer.short_link_properties?.agentId as string) ||
            checkoutReducer.agentId
          }
          agentName={checkoutReducer.agentName as string}
          periodicity={checkoutReducer.order?.dataOrderCompleted.periodicity}
          companyName={checkoutReducer.company as string}
          termsAndConditions={
            checkoutReducer.order?.dataOrderCompleted?.coupon
              ?.termsAndConditions
              ? checkoutReducer.order.dataOrderCompleted.coupon
                  .termsAndConditions
              : ''
          }
        ></CartCheckout>
        <div style={{ height: '40px' }}></div>
      </PopupDetailAccordion>
      <div className="checkout__personal__information__padding personal__information__title">
        <TitleWhitImage
          title="Completa tu <br /> información"
          image={Images.srConnectBlack}
          styleInLineImage={{ width: '2.62781rem', height: '6.25rem' }}
        ></TitleWhitImage>
      </div>
      <div className="grid rg-0 grid__col-6">
        <div className="checkout__personal__information__padding  grid__span-6">
          <Input
            label="Correo Electrónico"
            id="personal-information-email"
            type="email"
            name="email"
            onChange={formPersonalInformation.handleChange}
            onBlur={(e) => {
              handleFocus(e);
              handleComplemetInfo();
              updateInfoLead(
                'email',
                formPersonalInformation.values.email.toLocaleLowerCase()
              );
            }}
            value={formPersonalInformation.values.email}
            isTouched={formPersonalInformation.touched.email}
            errorInput={formPersonalInformation.errors.email}
            errorLabel={formPersonalInformation.errors.email}
            isRequired={true}
            form={formPersonalInformation}
            tooltip={
              'Necesario para poder abrir la cuenta con connect. Este será tu usuario para ingresar a tu panel administrativo'
            }
          />
          <Input
            label="Nombre"
            id="personal-information-name"
            type="text"
            name="name"
            onChange={formPersonalInformation.handleChange}
            onBlur={(event) => {
              updateInfoLead('name', formPersonalInformation.values.name);
              handleFocus(event);
            }}
            value={formPersonalInformation.values.name}
            isTouched={formPersonalInformation.touched.name}
            errorInput={formPersonalInformation.errors.name}
            errorLabel={formPersonalInformation.errors.name}
            isRequired={true}
            form={formPersonalInformation}
          />
          <Input
            label="Apellido"
            id="personal-information-lastname"
            type="text"
            name="lastname"
            onChange={formPersonalInformation.handleChange}
            onBlur={(event) => {
              updateInfoLead(
                'lastname',
                formPersonalInformation.values.lastname
              );
              handleFocus(event);
            }}
            value={formPersonalInformation.values.lastname}
            isTouched={formPersonalInformation.touched.lastname}
            errorInput={formPersonalInformation.errors.lastname}
            errorLabel={formPersonalInformation.errors.lastname}
            isRequired={true}
            form={formPersonalInformation}
          />
        </div>
        <Divider
          margin="notMargin"
          classWrapper="checkout__personal__information__divider--small personal__information__padding--small grid__span-6"
        />
        <div className="checkout__personal__information__padding grid rg-0 grid__col-6 grid__span-6">
          <Select
            WrapperClassName="grid__span-3"
            id="personal-information-country"
            placeholder="País"
            value={formPersonalInformation.values.country_id}
            name="country"
            onClick={handleOptionCountry}
            options={personalInformationConstants.countryOptions}
            isRequired={true}
            errorSelect={formPersonalInformation.errors.country_id}
            onBlur={formPersonalInformation.handleBlur}
            isTouched={formPersonalInformation.touched.country_id}
            isDisabled={checkoutReducer.isCountryDisabled}
          />
          {branch === 'pr' && (
            <Input
              WrapperClassName="grid__span-3"
              label="Código Postal"
              id="personal-information-zipCode"
              type="tel"
              name="zipCode"
              onChange={getZipCodeInfo}
              onBlur={formPersonalInformation.handleBlur}
              value={formPersonalInformation.values.zipCode}
              isTouched={formPersonalInformation.touched.zipCode}
              errorInput={formPersonalInformation.errors.zipCode}
              errorLabel={formPersonalInformation.errors.zipCode}
              isRequired={true}
              onClickDeleteAction={() => handleOptionDistrict('')}
            />
          )}
          {branch !== 'pr' && (
            <Select
              WrapperClassName="grid__span-3"
              id="personal-information-province"
              placeholder={
                personalInformationConstants.getLabels(branch)?.province
              }
              value={formPersonalInformation.values.province}
              name="province"
              onClick={(event) => handleOptionProvince(event)}
              options={countryAdministrativeDivision.provinces}
              errorSelect={formPersonalInformation.errors.province}
              isRequired={true}
              isTouched={formPersonalInformation.touched.province}
              onBlur={formPersonalInformation.handleBlur}
            />
          )}
          {['cr', 'pty'].includes(branch) && (
            <Select
              WrapperClassName="grid__span-3"
              id="personal-information-canton"
              placeholder="Cantón"
              value={formPersonalInformation.values.canton}
              name="canton"
              onClick={(event) => handleOptionCanton(event)}
              options={countryAdministrativeDivision.cantons}
              errorSelect={formPersonalInformation.errors.canton}
              isTouched={formPersonalInformation.touched.canton}
              isRequired={true}
              onBlur={formPersonalInformation.handleBlur}
            />
          )}
          <Select
            WrapperClassName="grid__span-3"
            id="personal-information-district"
            placeholder={
              personalInformationConstants.getLabels(branch)?.district
            }
            value={formPersonalInformation.values.district}
            name="district"
            onClick={handleOptionDistrict}
            options={countryAdministrativeDivision.districts}
            errorSelect={formPersonalInformation.errors.district}
            isTouched={formPersonalInformation.touched.district}
            isDisabled={branch === 'pr'}
            isRequired={true}
            onBlur={formPersonalInformation.handleBlur}
          />
        </div>
        <Divider
          margin="notMargin"
          classWrapper=" personal__information__divider--small personal__information__padding--small grid__span-6"
        />
        <div className="checkout__personal__information__padding grid__span-6">
          {branch !== 'pr' && (
            <Input
              label="Cédula Personal"
              id="personal-information-documentNumber"
              type="tel"
              name="documentNumber"
              onChange={formPersonalInformation.handleChange}
              onBlur={handleFocus}
              value={formPersonalInformation.values.documentNumber}
              isTouched={formPersonalInformation.touched.documentNumber}
              errorInput={formPersonalInformation.errors.documentNumber}
              errorLabel={formPersonalInformation.errors.documentNumber}
              isRequired={true}
              form={formPersonalInformation}
            />
          )}
          <SelectAndComplement
            label="Teléfono"
            nameSelect="indicator"
            idSelect="personal-information-indicator"
            onChange={formPersonalInformation.handleChange}
            onChangeSelect={handleIndicatorCountry}
            valueSelect={formPersonalInformation.values.indicator}
            errorSelect={formPersonalInformation.errors.indicator}
            options={personalInformationConstants.countryIndicatorOptions}
            idInput="personal-information-number"
            type="tel"
            nameInput="phone"
            onBlurInput={(event) => {
              updateInfoLead('phone', formPersonalInformation.values.phone);
              handleFocus(event);
            }}
            onBlurSelect={handleFocus}
            valueInput={formPersonalInformation.values.phone}
            inputIsTouched={formPersonalInformation.touched.phone}
            errorInput={formPersonalInformation.errors.phone}
            form={formPersonalInformation}
            selectIsDisabled={true}
          ></SelectAndComplement>
          <div className="container__btns">
            <div className="container__btns__checkbox">
              <div>
                {(checkoutReducer.resumen?.category as TypeProperties[]).some(
                  (elemento) =>
                    [
                      TypeProperties.AUTO,
                      TypeProperties.VEHICULOS,
                      TypeProperties.VEHICULOS_WITHOUT_ACCENT,
                      TypeProperties.MOTORA,
                      TypeProperties.MOTOCICLETA,
                    ].includes(elemento)
                ) && (
                  <Checkbox
                    id="accept-terms"
                    name="acceptTerms"
                    onChange={handleChangeAcceptTermsYearCar}
                    checked={formPersonalInformation.values.acceptTermsYearCar}
                  >
                    El auto es del {year} o más nuevo
                  </Checkbox>
                )}
                {formPersonalInformation.errors.acceptTermsYearCar ? (
                  <div className="alert__error__checkbox">
                    <ErrorSvg />
                    <span>Confirmar el año de tu auto</span>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div>
                <Checkbox
                  id="accept-terms"
                  name="acceptTerms"
                  onChange={handleChangeCheckTerms}
                  checked={formPersonalInformation.values.acceptTerms}
                  dangerouslySetInnerHTML={{
                    __html: checkoutReducer.terms_and_conditions as string,
                  }}
                />
                {formPersonalInformation.errors.acceptTerms ? (
                  <div className="alert__error__checkbox">
                    <ErrorSvg />
                    <span>Debes aceptar los términos y condiciones</span>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="checkout__personal__information__container__button checkout__personal__information__padding grid__span-6">
          <Button
            color="primary"
            size="medium"
            template="primary"
            typeStyle="rounded"
            type="button"
            isDisabled={
              formPersonalInformation.isSubmitting ||
              !formPersonalInformation.isValid
            }
            onClick={formPersonalInformation.handleSubmit}
            isLoading={formPersonalInformation.isSubmitting}
            className="medium"
          >
            continuar
          </Button>
        </div>
      </div>
      <HaveAccount onClick={() => navigate(`/login`, { state: null })} />
    </form>
  );
};
