// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface IdataCreateAccount {
  funelId: string;
  auth_id: string;
  plan_id: string;
  name: string;
  lastname: string;
  email: string;
  password: string;
  indicator: string | null;
  phone: string;
  country_id: number | null;
  acceptTerms: boolean;
  created: boolean;
  step: number;
  referenceId: string;
  id_user_product: number;
  user_plans_added: object;
  short_link: string;
  short_link_properties: IShortLink;
  resumen: {
    orderId: string;
    plans: string[];
    price: number;
  };
}

export const initalStateCreateAccount: IdataCreateAccount = {
  funelId: '',
  auth_id: '',
  plan_id: '',
  name: '',
  lastname: '',
  email: '',
  password: '',
  indicator: null,
  phone: '',
  country_id: null,
  acceptTerms: false,
  created: false,
  step: 0,
  id_user_product: 0,
  referenceId: '',
  user_plans_added: [],
  short_link: '',
  short_link_properties: {
    email: '',
    name: '',
    lastname: '',
    zipCode: '',
    phone: '',
    properties: [],
    agentId: '',
    branch: '',
    province: '',
    canton: '',
    document: '',
  },
  resumen: {
    orderId: '',
    plans: [],
    price: 0,
  },
};
export interface IShortLink {
  branch: string;
  email: string;
  name: string;
  lastname: string;
  zipCode: string;
  phone: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  properties: object | any;
  agentId: string;
  province: string;
  canton: string;
  document: string;
}

export interface IShortLinkTypeProperty {
  auto: object;
}
