import {
  HeaderWithSteps,
  PaymentMethodStripe,
  Button,
  PaymentMethodDlocal,
} from 'components';
import { useFormik } from 'formik';
import { IDetailStep } from 'models/info.steps.interface';
import { useNavigate } from 'react-router';
import { Elements } from '@stripe/react-stripe-js';
import './Add-payment-method.scss';

import {
  loadStripe,
  Stripe,
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js';
import { useLayoutEffect, useState, useRef } from 'react';
import { getKeyStripe } from 'utils/branch.utils';
import { useDispatch, useSelector } from 'react-redux';
import { IAppReducer } from 'models/reducer.interface';
import {
  IFormAddPaymentMethod,
  IFormErrorsAddPaymentMethod,
} from './models/Add-payment-method.interface';
import { addUserCard } from 'shared/services/user';
import { setStateError } from 'store/actions/app.types';
import { IInputFocusEvent } from 'components/molecules/Payment-method-dlocal/models/payment-method-dlocal.interface';
import { PaymentMethodStripeRef } from 'components/molecules/Payment-method-stripe/models/Payment-method-stripe';

export const AddPaymentMethod = () => {
  const dispatch = useDispatch();
  const branch = useSelector(
    (state: IAppReducer) => state.branchReducer.branch
  );
  const user = useSelector((state: IAppReducer) => state.authReducer);
  const stripeRef = useRef<PaymentMethodStripeRef>(null);
  const dlocalRef = useRef(null);
  const [stripeLoad, setStripeLoad] = useState<Stripe | null>(null);
  const navigate = useNavigate();
  const steps: IDetailStep[] = [
    {
      description: 'Nuevo método de pago',
      state: 'active',
      link: '/add-payment-method',
      permissionToNavigate: false,
    },
  ];

  useLayoutEffect(() => {
    const loadStripeElement = async () => {
      const stripeKey = getKeyStripe(branch);
      const stripe = (await loadStripe(stripeKey)) as Stripe;
      setStripeLoad(stripe);
    };
    loadStripeElement();
  }, [setStripeLoad]);

  const changeValueInput = (
    event:
      | StripeCardNumberElementChangeEvent
      | StripeCardExpiryElementChangeEvent
      | StripeCardCvcElementChangeEvent
      | IInputFocusEvent
  ) => {
    let value = '';
    if (event.complete) {
      value = 'complete';
    }
    let element: 'cardCvc' | 'cardExpiry' | 'cardNumber';
    if ('elementType' in event) {
      element = event.elementType;
    } else {
      element = event.element;
    }
    formAddPaymentMethod.setFieldValue(element, value);
    formAddPaymentMethod.values[element] = value;
  };

  const handleChangeCheck = (event: boolean | undefined) => {
    formAddPaymentMethod.setFieldValue('isPrincipal', !event);
  };
  const formAddPaymentMethodInitialValues: IFormAddPaymentMethod = {
    name: '',
    isPrincipal: false,
    cardNumber: '',
    cardExpiry: '',
    cardCvc: '',
  };

  const formAddPaymentMethodValidate = (values: IFormAddPaymentMethod) => {
    const errors: IFormErrorsAddPaymentMethod = {};
    if (!values.name) {
      errors.name = 'Campo requerido';
    }
    if (!values.cardNumber) {
      errors.cardNumber = 'Campo requerido';
    }
    if (!values.cardExpiry) {
      errors.cardExpiry = 'Campo requerido';
    }
    if (!values.cardCvc) {
      errors.cardCvc = 'Campo requerido';
    }
    return errors;
  };

  const formAddPaymentMethod = useFormik({
    initialValues: formAddPaymentMethodInitialValues,
    validate: formAddPaymentMethodValidate,
    onSubmit: async (values) => {
      try {
        let token = '';
        if (
          branch === 'pr' ||
          !user.formToShow ||
          user.formToShow.toLocaleLowerCase() === 'stripe'
        ) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          token = await (stripeRef?.current as any).getTokenCard();
        } else {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          token = await (dlocalRef?.current as any).getTokenCard();
        }
        await addUserCard(user.uid, token, values.isPrincipal);
        navigate('/settings/payments');
      } catch (error) {
        dispatch(setStateError(true));
      } finally {
        formAddPaymentMethod.setSubmitting(false);
      }
    },
  });
  return (
    <>
      <HeaderWithSteps
        notProgress={true}
        steps={steps}
        onClick={() => {
          navigate('/settings/payments');
        }}
      />
      <Elements stripe={stripeLoad}>
        <form
          className="add__payment__method__container container__generic"
          onSubmit={formAddPaymentMethod.handleSubmit}
          noValidate
        >
          {!user.formToShow ||
          user.formToShow.toLocaleLowerCase() === 'stripe' ? (
            <PaymentMethodStripe
              id="add-card-number"
              inputNameLabel="Nombre del titular de la tarjeta"
              inputNameControl={'name'}
              handleChangeNameInput={formAddPaymentMethod.handleChange}
              handleBlurNameInput={formAddPaymentMethod.handleBlur}
              touchedNameInput={formAddPaymentMethod.touched.name}
              errorNameInput={formAddPaymentMethod.errors.name}
              valueNameInput={formAddPaymentMethod.values.name}
              checkBoxNameControl={'isPrincipal'}
              checkBoxValue={formAddPaymentMethod.values.isPrincipal}
              handleChangeCheck={handleChangeCheck}
              form={formAddPaymentMethod}
              handleChangeStripeInput={changeValueInput}
              ref={stripeRef}
            />
          ) : (
            <PaymentMethodDlocal
              inputNameLabel="Nombre del titular de la tarjeta"
              inputNameControl="name"
              handleChangeNameInput={formAddPaymentMethod.handleChange}
              handleBlurNameInput={formAddPaymentMethod.handleBlur}
              touchedNameInput={formAddPaymentMethod.touched.name}
              errorNameInput={formAddPaymentMethod.errors.name}
              valueNameInput={formAddPaymentMethod.values.name}
              checkBoxNameControl={'isPrincipal'}
              checkBoxValue={formAddPaymentMethod.values.isPrincipal}
              handleChangeCheck={handleChangeCheck}
              form={formAddPaymentMethod}
              handleChangeDlocalInput={changeValueInput}
              ref={dlocalRef}
              country={branch as 'co' | 'cr'}
            ></PaymentMethodDlocal>
          )}
          <Button
            className="btn__add__payment__method"
            color="primary"
            type="submit"
            size="medium"
            template="primary"
            typeStyle="rounded"
            label="Guardar"
            isDisabled={
              formAddPaymentMethod.isSubmitting ||
              !formAddPaymentMethod.isValid ||
              !formAddPaymentMethod.dirty
            }
            isLoading={formAddPaymentMethod.isSubmitting}
            onClick={formAddPaymentMethod.handleSubmit}
          ></Button>
        </form>
      </Elements>
    </>
  );
};
