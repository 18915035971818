import { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { IAppReducer } from 'models/reducer.interface';
import { useCopyToClipboard } from 'hooks/useCopyToClipboard';
import { getPlanByCompanyId } from 'shared/services/plans';
import {
  setSignOut,
  setUser,
  updateUserAction,
} from 'pages/sing-in/store/actions/auth.types';
import { setBranch } from 'store/actions/branch.types';
import { signOut } from 'firebase/auth';
import { defaultFirebaseAuth } from 'config/firebase-config';
interface CustomizedState {
  infoPopUp: {
    showPopUp: boolean;
    email: string;
    name: string;
  };
}

export const useSettingsOptions = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: IAppReducer) => state.authReducer);
  const location = useLocation();
  const navigate = useNavigate();
  const { infoPopUp } = (location.state as CustomizedState) || {};
  const [popUp, setPopUp] = useState(false);
  const [, copy] = useCopyToClipboard();
  useEffect(() => {
    (async () => {
      if (!user.companyId) {
        return;
      }
      const response = await getPlanByCompanyId(user.companyId.toString());
      dispatch(
        updateUserAction({
          plansId: response.join(','),
        })
      );
    })();
  }, []);
  useLayoutEffect(() => {
    if (infoPopUp?.showPopUp) {
      sowPopUp();
    }
  }, [infoPopUp]);
  const sowPopUp = () => {
    setPopUp(true);
  };
  const hiddenPopUp = () => {
    setPopUp(false);
    navigate(location.pathname, {
      replace: true,
    });
  };
  const handleSignOut = async () => {
    try {
      if (user.agentAuthData?.accessToken) {
        dispatch(setBranch(user.agentAuthData.branch));
        dispatch(
          setUser({
            uid: user.agentAuthData.uid,
            name: user.agentAuthData.name,
            lastname: user.agentAuthData.lastname,
            email: user.agentAuthData.email,
            accessToken: user.agentAuthData.accessToken,
            userAuth: {
              phone: user.agentAuthData.userAuth.phone,
              phone_country_code:
                user.agentAuthData.userAuth.phone_country_code,
            },
            agentId: user.agentId,
            companyId: user.agentAuthData.companyId,
            plansId: user.agentAuthData.plansId,
            agentAuthData: {},
          })
        );
      } else {
        signOut(defaultFirebaseAuth);
        dispatch(setSignOut());
      }
    } catch (error) {
      // TODO Manejar error
    }
  };
  const handleCopy = (text: string) => async () => {
    try {
      await copy(text);
    } catch (error) {
      console.error('Failed to copy!', error);
    }
  };

  return { handleSignOut, user, handleCopy, popUp, hiddenPopUp };
};
