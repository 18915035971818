import {
  ButtonMoreOptions,
  Card,
  Chips,
  RadioButton,
  ItemStatus,
} from 'components';
import './Item-card.scss';

export interface IPropsItemCard {
  id: number | string;
  title: string;
  chipsInfo: string[];
  label: string;
  status: 'successful' | 'pending' | 'error' | 'cancelled' | 'timedOut';
  onClickDetail?: (id: number | string) => void;
  onClickRenewal?: (id: number | string) => void;
  onClickAddAddOns?: (id: number | string) => void;
  onClickEdit?: (id: number | string) => void;
  onClickDelete?: (id: number | string) => void;
  onClickSelect?: (id: number | string) => void;
  onClickCustomAction?: (id: number | string) => void;
  WrapperClassName?: string;
  isSelected?: boolean;
  type?: string;
  testid: string;
  isReadOnly?: boolean;
  labelDeleteOption?: string;
}

export const ItemCard = (props: IPropsItemCard) => {
  const messageStatus = {
    successful: 'Activo',
    pending: 'En espera',
    error: 'Error de Pago',
    cancelled: 'Cancelado',
    timedOut: 'Vencido',
  };
  return (
    <Card
      id={`item__card__${props.id}`}
      WrapperClassName={`item__card__container ${
        props.WrapperClassName ? props.WrapperClassName : ' '
      } ${props.onClickSelect ? 'is__clickable' : ' '}`}
      isSelected={props.isSelected}
    >
      {!props.isReadOnly && (
        <div className="item__card__more__options">
          <ButtonMoreOptions
            testid={props.testid}
            id={props.id}
            onClickDetail={props.onClickDetail}
            onClickEdit={props.onClickEdit}
            onClickDelete={props.onClickDelete}
            onClickCustomAction={props.onClickCustomAction}
            onClickRenewal={props.onClickRenewal}
            onClickAddAddOns={props.onClickAddAddOns}
            label={props.label}
            labelDeleteOption={props.labelDeleteOption}
          />
        </div>
      )}
      <div
        className={`item__card__content`}
        onClick={() => props.onClickSelect && props.onClickSelect(props.id)}
      >
        <div className="item__card__title__container">
          {props.onClickSelect && (
            <RadioButton isSelected={props.isSelected as boolean}></RadioButton>
          )}
          <div className="item__card__title">
            <span>
              {props.title} {props.type && '|'}
            </span>
            <strong>{props.type}</strong>
          </div>
        </div>
        <div className="item__card__chips">
          {props.chipsInfo.map((item, index) => (
            <Chips key={`${item}-${index}`} label={item} />
          ))}
        </div>
        <ItemStatus
          message={messageStatus[props.status]}
          status={props.status}
        ></ItemStatus>
      </div>
    </Card>
  );
};
