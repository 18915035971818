import { IProduct } from 'pages/Checkout/pages/Payment-information/models/Payment-information.interface';

export interface IdataCheckoutAction {
  company?: string | undefined | null;
  referrerId?: string;
  trialPeriod?: number;
  utms?: string | undefined;
  funelId?: string;
  auth_id?: string;
  plan_id?: string;
  name?: string;
  lastname?: string;
  email?: string;
  indicator?: string | undefined | null;
  phone?: string;
  country_id?: number | string | undefined | null;
  acceptTerms?: boolean;
  step?: number;
  id_user_product?: number;
  referenceId?: string;
  user_plans_added?: object;
  short_link?: string | null | undefined;
  short_link_properties?: IShortLink;
  accessToken?: string;
  zipCode?: string | number | undefined;
  documentNumber?: string;
  resumen?: {
    orderId?: string;
    category?: string[];
    plans?: string[];
    price?: number;
    subTotal?: number;
    discount?: number;
    discountCoupon?: number;
    discountForFreeTrial?: number;
  };
  order?: {
    id?: string;
    dataOrderCompleted?: any;
    dataProducts?: any;
  };
  isCountryDisabled?: boolean;
  products?: IProduct[];
  district?: string;
  province?: string;
  canton?: string;
  agentName?: string;
  agentId?: string;
  source?: string;
  companyAdditionalInformation?: {
    plate: string;
    documentNumber: number | undefined;
  };
  require_validation?: boolean;
  is_addon?: boolean;
  terms_and_conditions?: string;
}
export const initalStateCreateAccount: IdataCheckoutAction = {
  company: '',
  referrerId: '',
  utms: undefined,
  funelId: '',
  auth_id: '',
  plan_id: '',
  name: '',
  lastname: '',
  email: '',
  indicator: null,
  phone: '',
  country_id: null,
  acceptTerms: false,
  step: 0,
  id_user_product: 0,
  referenceId: '',
  user_plans_added: [],
  short_link: null,
  short_link_properties: {
    properties: {},
    agentId: '',
  },
  accessToken: '',
  resumen: {
    orderId: '',
    plans: [],
    category: [],
    price: 0,
    subTotal: 0,
    discount: 0,
    discountCoupon: 0,
    discountForFreeTrial: 0,
  },
  zipCode: '',
  documentNumber: '',
  order: {
    id: '',
    dataOrderCompleted: undefined,
    dataProducts: undefined,
  },
  isCountryDisabled: false,
  products: [],
  district: '',
  province: '',
  canton: '',
  companyAdditionalInformation: {
    plate: '',
    documentNumber: undefined,
  },
};
export interface IShortLink {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  properties: object | any;
  agentId: string;
}

export interface IShortLinkTypeProperty {
  auto: object;
}
