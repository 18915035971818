import { HistorySvg } from 'assets/icons/history';
import { Images } from 'assets/images/images.index';
import { ItemEmpty } from 'components/molecules/Item-empty/Item-empty';
import { getContactInfo } from 'utils/connect-info.utils';
interface IHistoryItemEmptyProps {
  branch: string;
  userType?: string;
}
export const HistoryItemEmpty = (props: IHistoryItemEmptyProps) => {
  console.log('rol', props.userType);

  return (
    <ItemEmpty
      title="Historial"
      image={<HistorySvg color="tertiary" />}
      info={`<strong>El historial de servicio está vacío o no está disponible en este momento</strong> (si este es el caso, contacte con servicio al cliente al ${
        getContactInfo(props.branch).phone
      }).`}
      template="secondary"
      link="tel:7879995050"
      typeAction={props.userType === 'colaborador' ? undefined : 'invoke'}
    ></ItemEmpty>
  );
};
