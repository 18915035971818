import { Images } from 'assets/images/images.index';
import { ButtonClose } from 'components/atoms/Button-close/Button-close';
import { Chips } from 'components/atoms/Chips/Chips';
import { PopupOverlay } from 'components/atoms/Popup-overlay/Popup-overlay';
import { ItemStatus } from 'components/atoms/Item-status/Item-status';

import * as paymentsUtils from 'utils/payments.utils';
import './Popup-delete.scss';

export interface IPopupDelete {
  title: string;
  itemTitle?: string;
  closePopUp: () => void;
  onAcceptAction: () => void;
  chipsInfo: string[];
  isLoading: boolean;
  type: 'deleteProperty' | 'deleteCardPayment' | 'deletePlan';
  brand?: string;
  lastDigits?: string;
  expire?: string;
  amountRefund?: string;
}

export const PopupDelete = (props: IPopupDelete) => {
  const renderElement = () => {
    switch (props.type) {
      case 'deleteProperty':
        return (
          <ItemStatus
            message={'Cubierto Plan anual 50%'}
            status="successful"
            big={true}
          ></ItemStatus>
        );
      case 'deleteCardPayment':
        return (
          <div className="popup__delete__detail__card">
            <img
              className="item__payment__logo"
              src={paymentsUtils.getIconCard(props.brand as string)}
              alt="Icono medio de pago"
            />
            <span>Terminada en {props.lastDigits}</span>
            <Chips label={props.expire as string} />
          </div>
        );
      case 'deletePlan':
        return (
          props.amountRefund ? (
            <span>
              Al eliminar el plan recibirá un reembolso de{' '}
              <strong>${props.amountRefund}</strong>.
            </span>
          ): ''
        );
    }
  };
  return (
    <PopupOverlay>
      <div className="popup__delete">
        <div className="popup__delete__header">
          <img
            className="popup__delete__header__icon"
            src={Images.warningDanger}
            alt="Advertencia de eliminar"
          />
          <div className="popup__delete__header__info">
            <span>{props.title}</span>
            <button
              onClick={() => !props.isLoading && props.onAcceptAction()}
              className={`popup__delete__header__info__button ${
                props.isLoading ? 'disabled' : ''
              }`}
            >
              sí, continuar
              {props.isLoading && (
                <div className="popup__delete__loader__container">
                  <div className="popup__delete__loader"></div>
                </div>
              )}
            </button>
          </div>
          <ButtonClose
            className="popup__delete__header__close"
            onClick={props.closePopUp}
            size="medium"
          />
        </div>

        <div
          style={{
            visibility: props.itemTitle ? 'visible' : 'hidden',
          }}
          className="popup__delete__title"
        >
          <hr className="popup__delete__title__divider" />
          <span>{props.itemTitle}</span>
        </div>
        <div className="popup__delete__detail">
          {props.type !== 'deleteCardPayment' && (
            <div className="popup__delete__detail__chips">
              {props.chipsInfo.map((item, index) => (
                <Chips key={`${item}-${index}`} label={item} />
              ))}
            </div>
          )}
          {renderElement()}
        </div>
      </div>
    </PopupOverlay>
  );
};
